const aiWorkshopTranslationsEn = {
  aiWorkshopTitle: "AIdeation Workshop",
  aiWorkshopP1:
    "We would like to share our experience on our 'AIdeation workshop' service with you.",
  aiWorkshopLi1: "AI is today not tomorrow.",
  aiWorkshopLi2:
    "As a software developer company, we have various experiences from our customers being curious about taking advantage of AI-based and data-driven solutions to compete with others. They feel that investing in AI is worth it, so we thought supporting them making this decision. That is why our 1,5-day workshop was created.",
  aiWorkshopLi3: "Who do we recommend this workshop to?",
  aiWorkshopLi3_li1:
    "From smaller companies to multinational companies that do not use AI solutions yet. Companies who believe in potential but do not know how and where to start.",

  aiWorkshopLi4: "How did our previous workshops go? What topics do we touch?",
  aiWorkshopLi4_li1:
    "The occasions were interactive with many questions that were clarified among together with the participants.",
  aiWorkshopLi4_li2: "What advantages and disadvantages does AI have?",
  aiWorkshopLi4_li3:
    "Difference between data-driven and instinctive ('natural') mentality",
  aiWorkshopLi4_li4: "Why now is the best time to start investing in AI.",
  aiWorkshopLi4_li5: "What is good and bad data?",
  aiWorkshopLi4_li6: "When to use and when not to use AI",
  aiWorkshopLi4_li7: "Neural networks",
  aiWorkshopLi4_li8:
    "Complete workflow of an AI project from the beginning to the end",
  aiWorkshopLi4_li9:
    "How to introduce data-driven mentality and decision supporting systems or tools",

  aiWorkshopLi5: "How personalized is the workshop?",
  aiWorkshopLi5_li1:
    "Participants are required to take a questionnaire which helps us know the prior knowledge and modify the topics so it would fit the best for our clients.",
  aiWorkshopLi5_li2:
    "We try to collect examples from the specific domain(s) of the audience.",

  aiWorkshopLi6:
    "We believe that a workshop like this is useful if not only the knowledge is shared with participants, but some ideas come up that can increase productivity day by day.",

  aiWorkshopLi7:
    "That is why we decided to connect our 1-day knowledge sharing workshop with an ideation workshop (1-day focusing on sharing knowledge + 0,5-day ideation)",

  aiWorkshopLi7_li1:
    "The goal of the ideation part is to be able to think in data-driven solutions and prioritize ideas based on business utilities and feasibility.",

  aiWorkshopLi7_li2:
    "One could ask: who will implement the solutions based on these ideas? Although this is not the primary goal of our workshop, in some cases we can also help in this.",

  aiWorkshopP2:
    "If you wish to develop your AI skills or are just simply curious about something new do not hesitate to contact us on one of our availabilities.",
};

export default aiWorkshopTranslationsEn;
