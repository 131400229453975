import React from 'react';
import cDeveloper from '../../doc/karrier/CSHARP_CPP_DeveloperBIM_CAD.pdf';
import pythonDeveloper from '../../doc/karrier/PythonBackendDeveloper.pdf';
import { useTranslation } from 'react-i18next';


export default function Career() {

    const { t } = useTranslation();
    return (
        <div id="karrier" className="w3-padding-32 w3-content w3-text-grey" style={{marginBottom:64}}>
            <h2>{t('career')}</h2>
            <hr className="w3-opacity" />
            <h3 id="nyitott">{t('openPosition')}</h3>
            <div className="w3-cell-row">
                <div className="w3-container w3-cell w3-mobile">
                    <h4>
                        <i className="fa fa-building fa-fw w3-xxlarge w3-margin-right"></i>
                        <a href={cDeveloper} target="_blank">{t('openPositionCSharp')}</a>
                    </h4>
                </div>
                <div className="w3-container w3-cell w3-mobile">
                    <h4>
                        <i className="fa fa-steam fa-fw w3-xxlarge w3-margin-right"></i>
                        <a href={pythonDeveloper} target="_blank">Python Backend Developer</a>
                    </h4>
                </div>
            </div>
            <h4>{t('careerText')}</h4>
        </div>
    )
}
