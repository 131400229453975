import cadBimTranslationsHu from "./cadBimTranslationsHu";
import gisTranslationsHu from "./gisTranslationsHu";
import objectDetectionTranslationsHu from "./objectDetectionTranslationsHu";
import aiWorkshopTranslationsHu from "./aiWorkshopTranslationsHu";
import waterMeterTranslationsHu from "./watermeterTranslationsHu";

const referenceTranslationsHu = {
  generativAICardTitle: "Innosw AI Image Generator",
  generativAICardText:
    "Skiccek, egyszerű 3D nézetek lenyűgöző látványtervekké alakítása",
  objectDetectionCardTitle: "Objektum felismerés AI alapon",
  objectDetectionCardText:
    "Tetszőleges szimbólum felismerése személyre szabható AI modellek segítségével",
  cadBimCardTitle: "CAD/BIM fejlesztések",
  cadBimCardText:
    "Tervezési és mérnöki munka hatékonyságának növelése egyedi kiegészítők és automatizálás segítségével",
  simulationCardTitle: "3D szimulációk",
  simulationCardText:
    "Virtuális 3D modelleken végzett szimulációk és számítások Unity3D platformon",
  gisCardTitle: "Térinformatika",
  gisCardText:
    "Nagy mennyiségű földrajzi adat értelmezése, feldolgozása és kiegészítése",
  watermeterCardTitle: "Vízóracsere Menedzsment Rendszer",
  watermeterCardText: "Teljes munkafolyamat menedzsment web és mobil alapon",
  workshopCardTitle: "AIdeation workshop",
  workshopCardText:
    "Mesterséges intelligencia alkalmazására való készségek fejlesztése másfél napban, csapatoknak",

  ...cadBimTranslationsHu,
  ...gisTranslationsHu,
  ...objectDetectionTranslationsHu,
  ...aiWorkshopTranslationsHu,
  ...waterMeterTranslationsHu,
};

export default referenceTranslationsHu;
