import { useEffect, useState } from "react";
import { BiArrowFromBottom } from 'react-icons/bi';
import { classNames } from '../../utils/classNames';


export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 20) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const ScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        }
    }, []);

    return (
        <div className="fixed bottom-2 right-2">
            <button type='button' onClick={ScrollToTop}
                className={classNames(
                    isVisible ? 'opacity-100' : 'opacity-0', 'inline-flex items-center p-3 rounded-full shadow-sm text-black bg-red-600 hover:bg-gray-700'
                )}
            >
                <BiArrowFromBottom />
            </button>
        </div>
    )
};
