import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './w3.css';
import { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import AboutUs from './components/AboutUs/AboutUs';
import Partners from './components/Partners/Partners';
import Career from './components/Career/Career';
import References from './components/References/References';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import { ScrollToTop } from './components/Scroll';
import CarouselComponent from './components/Carousel/Carousel';


function App() {
  const [settings, setSettings] = useState();

  const fetchJson = () => {
    fetch('./settings.json')
      .then(response => {
        return response.json();
      }).then(data => {
        localStorage.setItem('xmas', data.xmas);
      })
  }

  useEffect(() => {
    fetchJson()
  }, []);

  return (
    <div className="App">
      <Header  />
      <CarouselComponent />
      {/* Rólunk */}
      <AboutUs />
      {/* Partnereink  */}
      <Partners />
      {/* Karrier  */}
      <References />
      {/* Referenciák  */}
      <Career />
      {/* Kapcsolat  */}
      <Contact />
      {/* Footer */}
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
