const autoCadTranslationsHu = {
  autoCadTitle: "Autodesk – AutoCAD",
  autoCadLi1: "Elektromos elosztószekrény tervezés",
};

const revitTranslationsHu = {
  revitTitle: "Autodesk – Revit",
  revitLi1: "Automatikus publikálás",
  revitLi2: "Automatikus méretezés",
  revitLi3: "Feltételes kijelölés",
  revitLi4: "Faláttörések azonosítása",
  revitLi5: "WinWatt együttműködés",
};

const navisWorksTranslationsHu = {
  navisWorksTitle: "Autodesk – NavisWorks",
  navisWorksLi1: "Automatikus modellfrissítés",
  navisWorksLi2: "Ütközések csoportosítása",
  navisWorksLi3: "Ütközésvizsgálat ellenőrzése, automatikus javítása",
};

const archiCadTranslationsHu = {
  archiCadTitle: "Graphisoft – ArchiCAD",
  archiCadLi1: "Automatikus publikálás",
  archiCadLi2: "Felületi mennyiségszámítás helyiségenként",
  archiCadLi3: "Helyiséglista tulajdonságainak automatikus frissítése",
  archiCadLi4: "Épületelemek geometria alapján történő egységes számozása",
  archiCadLi5: "DWG fordítók szinkronizálása",
  archiCadLi6: "Hágcsótervező",
  archiCadLi7: "Automatikus kótázás",
};

const axisVmTranslationsHu = {
  axisVmTitle: "AxisVM",
  axisVmLi1: "Csomóponti igénybevételek kigyűjtése",
  axisVmLi2: "Lüktető feszültségek lekérdezése",
  axisVmLi3: "Mértékadó támaszerők lekérdezése",
  axisVmLi4: "Vasbeton pillér méretezés",
};

const cadBimTranslationsHu = {
  cadBimTitle: "CAD/BIM megoldásaink",
  cadBimP1:
    "CAD/BIM fejlesztéseink az Autodesk termékei mellett Graphisoft ArchiCad-re és AxisVM-re készülnek. Céljuk a tervezés, dokumentáció, mennyiségszámítás, illetve kollaboráció során felmerülő repetitív feladatok automatizálása, illetve a folyamatok hatékonyságának minél nagyobb mértékű növelése.",
  cadBimP2: "Az eddig elkészült kiegészítőink, alkalmazásaink:",
  ...autoCadTranslationsHu,
  ...revitTranslationsHu,
  ...navisWorksTranslationsHu,
  ...archiCadTranslationsHu,
  ...axisVmTranslationsHu,
};

export default cadBimTranslationsHu;
