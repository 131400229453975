const solarPanelTranslationsDe = {
  solarPanelTitle: "Schätzung des städtischen Solarenergiepotenzials",
  solarPanelP1:
    "In diesem Projekt haben wir das Energieerzeugungspotenzial von städtischen Dächern und Parkplätzen geschätzt. Der Prozess begann mit der Erstellung eines detaillierten 3D-Geländemodells der Stadt mit Unity, das auf einem GeoTIFF-Bild der Stadt aus der Draufsicht basierte. In diesem Bild gab der Pixelwert die Höhe eines bestimmten Punktes über dem Meeresspiegel an, so dass wir die Stadtlandschaft genau abbilden konnten.",
  solarPanelP2:
    "Als Nächstes extrahierten wir die Koordinaten der Scheitelpunkte jedes Daches aus Geojson-Dateien. Anhand dieser Koordinaten konnten wir die Solarmodule mit großer Genauigkeit auf den 3D-Modellen der Dächer platzieren, wobei wir darauf geachtet haben, die Flächenausnutzung zu optimieren. Außerdem haben wir sorgfältig auf die Anordnung der Solarmodule geachtet, um sie an die Ausrichtung der Flach- und Schrägdächer anzupassen, damit sie so viel Sonnenlicht wie möglich erhalten.",
  solarPanelP3:
    "Das Schlüsselelement des Projekts war die Simulation von Abschattungseffekten in Unity. Indem wir die Bewegung der Sonne und ihre Interaktion mit den umliegenden Gebäuden modellierten, konnten wir das Volumen der Beleuchtungsstärke der Solarmodule bestimmen, was uns eine sehr genaue Schätzung der Energieproduktion der Solarmodule ermöglichte.",
  solarPanelP4:
    "Bei der Nutzung der Software arbeitete unser Partner mit etwa 300.000 Dächern, auf denen wir fast 3 Millionen Solarmodule angebracht hatten. Darüber hinaus verfügten sie über Sonneneinstrahlungsdaten für jede Stunde jedes Tages für ein Jahr, was zu einer enormen Datenmenge führte, da die Strahlungsdaten für jedes einzelne Solarpanel (etwa 3 Millionen) verwendet werden mussten. Daher erforderte dieses Projekt eine extreme Menge an Rechenleistung, doch unser Softwareentwurf ermöglichte eine effiziente Parallelisierung, wodurch die Verarbeitungszeit erheblich reduziert werden konnte. Ohne Parallelisierung hätten die Berechnungen bis zu einem Monat dauern können, aber so konnte unser Kunde nahtlos mit seiner Arbeit fortfahren.",
};

const acVolumeCalculationTranslationsDe = {
  acVolumeCalculationTitle:
    "Berechnung der Oberflächenmaterialmenge in ArchiCAD",
  acVolumeCalculationP1:
    "Ziel des Programms ist es, auf der Grundlage des in ArchiCAD erstellten BIM-Modells die Mengen der Oberflächenmaterialien in jedem Raum genau zu berechnen. Die Entwicklung war notwendig, weil die eingebaute Mengenberechnung in ArchiCAD bei nicht ausreichend präziser 3D-Modellierung keine genauen Ergebnisse liefert. Wenn z.B. bestimmte Bauelemente nicht exakt an ihren Oberflächen anliegen, sondern es eine volumetrische Überschneidung zwischen ihnen gibt, wird ArchiCAD bereits eine recht ungenaue Berechnung erstellen.",
  acVolumeCalculationP2:
    "Die Lösung von InnoSW exportiert das in ArchiCAD erstellte 3D-Modell der Gebäudeelemente in eine Zwischendatei und führt dann die Mengenermittlung in Unity 3D durch. Unity verfügt über eine Fülle von eingebauten Funktionen für den Umgang mit dreidimensionalen Oberflächennetzen, Vektorgeometrieoperationen und die Programmierung von Raytracing. Die Funktionsbibliotheken von Unity 3D ermöglichen darüber hinaus eine einfache Parallelisierung der Berechnungen. Dadurch konnte der Zeitaufwand für die Entwicklung erheblich reduziert werden.",
  acVolumeCalculationP3:
    "Die Oberflächenberechnung selbst basiert auf Wahrscheinlichkeit und Stichproben. Der Algorithmus nimmt nach dem Zufallsprinzip mehrere Millionen Stichproben mit einer gleichmäßigen Verteilung auf der Oberfläche des exportierten Dreiecksnetzes. An jedem Abtastpunkt wird entschieden, ob der betreffende Punkt sichtbar ist oder von anderen Modellelementen verdeckt wird. Für jedes Dreieck kann anhand des Verhältnisses zwischen den verdeckten Stichprobenpunkten und der Gesamtzahl der Stichproben berechnet werden, wie viel Fläche des Dreiecks zur zu berechnenden Gesamtfläche beiträgt.",
  acVolumeCalculationP4:
    "Nach der Probenahme summiert das Programm die Flächen der Dreiecke, die in jedem Material in jedem Raum gefunden wurden, und ermittelt so die genaue Flächenmenge für jedes Material. Die berechneten Mengen werden in Form einer Liste an ArchiCAD zurückgegeben, in der jeder Mengenwert auch der Raum- und Materialkennung zugeordnet ist.",
};

const simulationTranslationsDe = {
  ...solarPanelTranslationsDe,
  ...acVolumeCalculationTranslationsDe,
};

export default simulationTranslationsDe;
