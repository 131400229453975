const gisTranslationsHu = {
  gisTitle: "Térinformatika",
  gisContentP1:
    "Projektünk során egy specifikus városi terület napenergia kapacitását határoztuk meg. Részletes LOD modellt  használtunk, amely LIDAR pontfelhő adatok osztályozásából állt össze. A modell tartalmazott épületeket, azok tetőit és falait illetve parkolókat.  Célunk az volt, hogy kiszámoljuk a napenergia kapacitást (KWP) minden tető és parkoló számára ezen a területen.",
  gisContentP2:
    "Az LOD2 modellt exportáltuk GIS szoftverből (QGIS), majd importáltuk azt a Unity 3D szoftverbe. Ezt követően gondosan megterveztük egy optimalizált mohó algoritmus segítségével a napelemkiosztásokat az egyes tetők és parkolók esetében, különböző szabályokat figyelembe véve. Az adatbiztonság érdekében hatékony hibrid titkosítási algoritmust használtunk az minden adatátvitel során.",
  gisContentP3:
    "A projekt magában foglalta a terepmodell alapján történő árnyékhatás keltő szimulációk végrehajtását minden órában, az adatok összegzését olyan épületeknél, amelyek több tetővel rendelkeznek, és végül a keletkező adatok exportálását GIS formátumban. Ez a projekt nagyban hozzájárult ahhoz, hogy bővíthessük szekértelmünket a térinformatika területein, valamint, hogy értékes betekintést nyújtsunk a városi környezetekben rejlő napenergia potenciálba.",
};

export default gisTranslationsHu;
