import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Header from "../../Header/Header";
import SnowComponent from "../../Snow/Snow";

export default function ObjectDetection() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Container fluid style={{ marginTop: "50px" }}>
        <Row style={{ justifyContent: "space-evenly" }}>
          <Col sm={5}>
            <img
              src="images/references/object_detection.png"
              style={{
                border: "2px solid darkgray",
                boxShadow: "2px 4px 18px #707070",
                marginTop: "20px",
              }}
              alt=""
            />
            <img
              src="images/references/object_detection2.png"
              style={{
                border: "2px solid darkgray",
                boxShadow: "2px 4px 18px #707070",
                marginTop: "20px",
              }}
              alt=""
            ></img>
          </Col>
          <Col sm={5}>
            <h2 style={{ marginBotton: "40px" }}>
              {t("objectDetectionPageTitle")}
            </h2>
            <p>{t("objectDetectionContentP2")}</p>
            <p>{t("objectDetectionContentP2")}</p>
            <p>{t("objectDetectionContentP2")}</p>
          </Col>
        </Row>
      </Container>
      <SnowComponent />
    </>
  );
}
