import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Header from "../../../Header/Header";
import SnowComponent from "../../../Snow/Snow";

export default function WaterMeter() {
  const { t } = useTranslation();

  const keyFeatures = [];
  for (let i = 1; i <= 13; i++) {
    // Assuming there are 13 key features
    keyFeatures.push(<li key={`keyFeatureLi${i}`}>{t(`keyFeatureLi${i}`)}</li>);
  }

  const mobileAppFeatures = [];
  for (let i = 1; i <= 10; i++) {
    // Assuming there are 10 mobile app features
    mobileAppFeatures.push(
      <li key={`mobileAppFeatureLi${i}`}>{t(`mobileAppFeatureLi${i}`)}</li>
    );
  }

  return (
    <>
      <Header />
      <Container fluid style={{ marginTop: "50px" }}>
        <Row style={{ justifyContent: "space-evenly" }}>
          <Col sm={5}>
            <img
              src="images/watermeter.png"
              style={{
                border: "2px solid darkgray",
                boxShadow: "2px 4px 18px #707070",
                marginTop: "20px",
              }}
              alt="Water Meter System"
            />
          </Col>
          <Col sm={5}>
            <h2 style={{ marginBottom: "40px" }}>{t("title")}</h2>
            <p>{t("description")}</p>
            <p>{t("usability")}</p>
            <h3>{t("keyFeatures")}</h3>
            <ul>{keyFeatures}</ul>
            <h3>{t("mobileAppFeatures")}</h3>
            <ul>{mobileAppFeatures}</ul>
            <p>{t("evolutionStatement")}</p>
          </Col>
        </Row>
      </Container>
      <SnowComponent />
    </>
  );
}
