import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import Header from "../../../Header/Header";
import SnowComponent from "../../../Snow/Snow";
import ReferenceCard from "../../ReferenceCard";
import useCadBimContent from "./cadBimContent";

export default function CadBimDevelopments() {
  const { t } = useTranslation();
  const {
    archiCadContent,
    autoCadContent,
    revitContent,
    navisWorksContent,
    axisVmContent,
  } = useCadBimContent();

  console.log(archiCadContent);

  return (
    <>
      <Header />
      <Container fluid style={{ marginTop: "50px", maxWidth: "1920px" }}>
        <div
          id="references"
          className="w3-padding-32 w3-content w3-text-grey"
          style={{ marginBottom: 64 }}
        >
          <h2>{t("cadBimTitle")}</h2>
          <p>{t("cadBimP1")}</p>
          <p>{t("cadBimP2")}</p>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <ReferenceCard
              title={t("archiCadTitle")}
              text={archiCadContent}
              imagePath="/images/references/CAD_BIM/archiCad.png"
            />
            <ReferenceCard
              title={t("autoCadTitle")}
              text={autoCadContent}
              imagePath="/images/references/CAD_BIM/autoCad.jfif"
            />
            <ReferenceCard
              title={t("revitTitle")}
              text={revitContent}
              imagePath="/images/references/CAD_BIM/revit.jfif"
            />
            <ReferenceCard
              title={t("navisWorksTitle")}
              text={navisWorksContent}
              imagePath="/images/references/CAD_BIM/navis.jfif"
            />
            <ReferenceCard
              title={t("axisVmTitle")}
              text={axisVmContent}
              imagePath="/images/references/CAD_BIM/axis.png"
            />
          </div>
        </div>
      </Container>
      <SnowComponent />
    </>
  );
}
