import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";

export default function ReferenceCard({
  imagePath,
  title,
  text,
  navigateTo,
  isExternalLink = false,
}) {
  const navigate = useNavigate();

  const cardStyle = {
    width: "18rem",
    margin: "25px",
    border: "2px solid darkgray",
    boxShadow: "2px 4px 18px #707070",
    cursor: navigateTo ? "pointer" : "default",
  };

  const handleClick = () => {
    if (navigateTo) {
      if (isExternalLink) {
        window.open(navigateTo, "_blank");
      } else {
        navigate(navigateTo);
      }
    }
  };

  return (
    <Card onClick={handleClick} className="card" style={cardStyle}>
      {imagePath && (
        <Card.Img
          variant="top"
          src={imagePath}
          style={{ width: "300px", height: "200px", objectFit: "contain" }}
        />
      )}
      <Card.Body style={{ backgroundColor: "#A8A8A8", color: "white" }}>
        <Card.Title style={{ textAlign: "center", fontWeight: "bold" }}>
          {title}
        </Card.Title>
        <Card.Text style={{ textAlign: "center" }}>{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}
