import { useTranslation } from 'react-i18next';


export default function AboutUs() {

    const { t } = useTranslation();

    return (
        <>
        { localStorage.getItem('xmas') === 'true' ? (
            <div className="w3-padding-32 w3-content w3-text-grey" style={{marginBottom:10}}>
                <div className="w3-cell-row">
                    <div id="xmas" className="w3-container w3-cell w3-text-red w3-mobile w3-margin-right">
                        <h1>{t('christmas')}</h1>
                    </div>
                    <div id="xmas" className="w3-container w3-cell w3-mobile">
                        <img src="images/xmas.jpg" className="w3-round" style={{width:'40%'}} alt="Xmas" />
                    </div>
                </div>
            </div>
        ) : '' }

        <div id="rolunk" className="w3-padding-32 w3-content w3-text-grey" style={{ marginBottom: 64 }}>
         <h2>{t('aboutus')}</h2>
            <hr className="w3-opacity" />
            <h4>{t('aboutText')}</h4>
            <h4>{t('aboutText2')}</h4>
            <h4>{t('aboutText3')}</h4>
            <h4>{t('aboutText4')}</h4>
            <h4>{t('aboutText5')}</h4>
        </div> 
        </>
    )
}
