const autoCadTranslationsEn = {
  autoCadTitle: "Autodesk – AutoCAD",
  autoCadLi1: "Electrical distribution cabinet design",
};

const revitTranslationsEn = {
  revitTitle: "Autodesk – Revit",
  revitLi1: "Automatic publishing",
  revitLi2: "Automatic dimensioning",
  revitLi3: "Conditional selection",
  revitLi4: "Void provision identification",
  revitLi5: "WinWatt connection",
};

const navisWorksTranslationsEn = {
  navisWorksTitle: "Autodesk – NavisWorks",
  navisWorksLi1: "Automatic model update",
  navisWorksLi2: "Clash grouping",
  navisWorksLi3: "Rerun and Fix automatic clash detection",
};

const archiCadTranslationsEn = {
  archiCadTitle: "Graphisoft – ArchiCAD",
  archiCadLi1: "Automatic publishing",
  archiCadLi2: "Surface area calculation for each zone",
  archiCadLi3: "Automatic update of zone properties",
  archiCadLi4: "Identify building elements by geometry",
  archiCadLi5: "DWG translator synchronization",
  archiCadLi6: "Fixed ladder design",
  archiCadLi7: "Automatic dimensioning",
};

const axisVmTranslationsEn = {
  axisVmTitle: "AxisVM",
  axisVmLi1: "Querying nodal forces and moments",
  axisVmLi2: "Querying of fluctuating stresses for fatigue analysis",
  axisVmLi3: "Querying critical support forces",
  axisVmLi4: "Design of reinforced concrete columns",
};

const cadBimTranslationsEn = {
  cadBimTitle: "CAD/BIM Solutions",
  cadBimP1:
    "Our CAD/BIM developments are made alongside Autodesk products, also for Graphisoft ArchiCAD and AxisVM. Their purpose is to automate repetitive tasks that arise during design, documentation, quantity calculation, as well as collaboration, and to increase the efficiency of these processes to the greatest possible extent.",
  cadBimP2: "Our completed applications and add-ons so far:",
  ...autoCadTranslationsEn,
  ...revitTranslationsEn,
  ...navisWorksTranslationsEn,
  ...archiCadTranslationsEn,
  ...axisVmTranslationsEn,
};

export default cadBimTranslationsEn;
