const solarPanelTranslationsHu = {
  solarPanelTitle: "Városi napenergia potenciál becslése",
  solarPanelP1:
    "Ebben a projektben városi háztetők, illetve parkolók energiatermelési potenciáljára adtunk becslést. A folyamat azzal kezdődött, hogy a városról felülnézetből készült GeoTIFF formátumú kép alapján részletes 3D-s terepmodellt készítettünk Unity segítségével. Ezen a képen, az egyes pixelek értéke megadta, hogy az adott pont milyen magasan helyezkedik el a tengerszint felett, így lehetővé tette számunkra a városi táj pontos leképezését.",
  solarPanelP2:
    "Ezután geojson fájlokból kinyertük az egyes háztetők csúcsainak koordinátáit. Ezen koordináták segítségével, nagy pontossággal tudtuk elhelyezni a napelemeket a tetők 3D modelljein, miközben ügyeltünk a minél jobb felület kihasználtságra. Továbbá a napelemek elhelyezése során kiemelt figyelmet fordítottunk arra, hogy a lapos és ferde tetők tájolásához igazítsuk őket, így biztosítva, hogy a lehető legtöbb napfényt kapják.",
  solarPanelP3:
    "A projekt kulcseleme az árnyékoló hatások szimulációja volt a Unity rendszeren belül. A nap mozgásának és a környező épületekkel való kölcsönhatásának modellezésével képesek voltunk meghatározni a napelemek megvilágítottságának mértékét, aminek köszönhetően nagy pontosságú becslést tudtunk adni a napelemek energia termeléséről.",
  solarPanelP4:
    "Partnerünk a szoftver használata során, közel 300000 tetővel dolgozott, amelyekre mi hozzávetőlegesen 3 millió napelemet osztottunk ki. Ezen kívül rendelkezésükre állt egy évre visszamenőleg, minden nap minden órájára vonatkozó napsugárzási adat, aminek eredményeként óriási adatmennyiség keletkezett, hiszen ezeket az adatokat minden egyes napelem (kb. 3 millió db) esetében fel kellett használni. Emiatt nagyobb számítási kapacitás vált szükségessé, azonban a programunk kialakítása lehetővé tette a hatékony párhuzamosítást, ami jelentősen csökkentette a feldolgozási időt. Párhuzamosítás nélkül a számítások akár egy hónapig is tarthattak volna, így azonban ügyfelünk zökkenőmentesen végezhette a munkáját.",
};

const acVolumeCalculationTranslationsHu = {
  acVolumeCalculationTitle: "Felületi anyagmennyiség számítása ArchiCAD-ben",
  acVolumeCalculationP1:
    "A program célja az ArchiCAD-ben elkészített BIM modell alapján az egyes helyiségekben található felületi anyagmennyiségek pontos kiszámítása. A fejlesztésre azért volt szükség, mert az ArchiCAD beépített mennyiségszámítása nem kellően precíz 3D modellezés esetén nem ad pontos eredményeket. Ha például bizonyos épületelemek nem pontosan a felületükön érintkeznek, hanem térfogati összemetsződés van köztük, az ArchiCAD már meglehetősen pontatlan számítást végez.",
  acVolumeCalculationP2:
    "Az InnoSW megoldása az ArchiCAD-ben létrehozott épületelemekből álló 3D modellt előbb egy köztes fájlba exportálja, majd a mennyiségszámítást Unity 3D-ben végzi el. A Unity rengeteg beépített függvényt tartalmaz a háromdimenziós felülethálók kezelésére, vektorgeometriai műveletekre és a sugárkövetéssel kapcsolatos számítások programozására. Ezen kívül a számítások könnyű párhuzamosíthatósága is megoldott a Unity 3D függvénykönyvtáraiban. A fentieknek köszönhetően a fejlesztéshez szükséges időt nagymértékben le tudtuk csökkenteni.",
  acVolumeCalculationP3:
    "Maga a felületszámítás valószínűségi alapon, mintavételezéssel történik. Az algoritmus véletlenszerűen, egyenletes eloszlással több millió mintát vesz a kiexportált háromszögháló felületén. Az egyes mintavételi pontokban eldönti, hogy az adott pont látható, vagy más modellelemek által kitakart pozícióban van-e. Az egyes háromszögek esetében a kitakart mintavételi pontok és az összes mintavétel arányszámából kiszámítható, hogy az adott háromszög mekkora felületrésszel járul a teljes számítandó felülethez.",
  actVolumeCalculationP4:
    "A mintavételezést követően a program anyagonként összegzi az egyes helyiségekben található háromszögek felületeit, így adódik minden egyes anyaghoz a pontos felületi mennyiség. A kiszámított mennyiségek egy a lista formájában kerülnek vissza az ArchiCAD-be, amelyben minden egyes mennyiségértékhez hozzá van rendelve a helyiség és az anyag azonosítója is.",
};

const simulationTranslationsHu = {
  ...solarPanelTranslationsHu,
  ...acVolumeCalculationTranslationsHu,
};

export default simulationTranslationsHu;
