const waterMeterTranslationsDe = {
  title: "Verwaltungssystem für den Austausch von Wasserzählern",
  description:
    "Ein System zur Verwaltung des Wasserzählerwechsels und aller damit verbundenen Arbeitsabläufe.",
  usability:
    "Unsere Web- und Mobil-App ist einfach und sicher zu bedienen und ermöglicht es Ihnen, Ihre täglichen Arbeitsabläufe mit Leichtigkeit zu verfolgen.",

  keyFeatures: "Hauptmerkmale",
  keyFeatureLi1: "Nutzung der neuesten webbasierten Technologie",
  keyFeatureLi2:
    "Verwaltung von Plomben- und Wasserzählerbestand, Plomben- und Wasserzählertypen, Abfragen, welche Plomben an wem angebracht sind, wo sie installiert sind usw.",
  keyFeatureLi3: "Google-Adressensuche bei Auftragserteilung",
  keyFeatureLi4:
    "Preis und Arbeitszeit werden anhand der Preisliste ausgewählt",
  keyFeatureLi5: "Flexible Einstellmöglichkeit des Zeitfensters",
  keyFeatureLi6:
    "Automatische Planung in zwei Phasen: Klempnerzuweisung und anschließende Routenplanung",
  keyFeatureLi7:
    "Google-basierte verkehrsabhängige Tagesplanung, Fokus auf Auslastungssteigerung",
  keyFeatureLi8: "Möglichkeit der manuellen Planung",
  keyFeatureLi9:
    "Kundenkommunikation: Auftragsbestätigung und voraussichtliche Ankunftszeit per E-Mail",
  keyFeatureLi10:
    "Auftragskommissionierung über Kalenderplaner (unter Berücksichtigung der Auslastung, der an diesem Tag aktiv besuchten Bezirke/Städte)",
  keyFeatureLi11:
    "Arbeitsnachverfolgung, Nachfakturierungsoption, Anzeige des Fakturierungsstatus, Verknüpfung mit externem Fakturierungsanbieter",
  keyFeatureLi12: "Datenexport nach Excel, Auswertungen",
  keyFeatureLi13: "Tätigkeitsprotokoll",

  mobileAppFeatures: "Die wichtigsten Funktionen der mobilen App",
  mobileAppFeatureLi1:
    "Erfassung von Wasserzählerwechseln und anderen Positionen",
  mobileAppFeatureLi2:
    "Plomben + Wasserzähler-ID: Barcode-Lesung + durchsuchbare Listenauswahlmöglichkeit",
  mobileAppFeatureLi3:
    "Datenaufzeichnung ohne Internetverbindung möglich, sogar der ganze Tag kann ohne Internet aufgezeichnet werden",
  mobileAppFeatureLi4:
    "Rechnungsstellung über externen Rechnungsstellungsanbieter (szamlazz.hu) API, Rechnung wird per E-Mail versendet und/oder lokal ausgedruckt",
  mobileAppFeatureLi5:
    "Aufnahme von Fotos, Sicherung auf mobilem Gerät und in der Zentrale",
  mobileAppFeatureLi6: "Dokumentation von Fehlleistungen",
  mobileAppFeatureLi7: "Ausfüllen des Arbeitsblattes in digitaler Form",
  mobileAppFeatureLi8: "Arbeitsblatt vom Kunden digital signiert",
  mobileAppFeatureLi9: "Arbeitsblatt per E-Mail versenden",
  mobileAppFeatureLi10:
    "Auftragsdetails sofort im Zentrum verfügbar, zusammen mit dem unterschriebenen Arbeitsblatt",

  evolutionStatement:
    "Das System wird je nach Bedarf ständig weiterentwickelt.",
};

export default waterMeterTranslationsDe;
