import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedinIn, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Contact() {

    const { t } = useTranslation();

    return (
        <>
            <div className="w3-padding-32 w3-content w3-text-grey" id="kapcsolat" style={{ marginBottom: 64}}>
                <h2>{t('contact')}</h2>
                <hr className="w3-opacity" />
                <div className="w3-section" style={{ marginLeft: 10}}>
                    <p><FontAwesomeIcon icon={faLocationDot} /><i className="fa fa-location-dot fa-fw w3-xxlarge w3-margin-right"></i> 2120 Dunakeszi, Fő út 41.</p>
                    <p><FontAwesomeIcon icon={faPhone} /><i className="fa fa-phone fa-fw w3-xxlarge w3-margin-right"></i> Phone: +36 30 813 3736</p>
                    <p><FontAwesomeIcon icon={faEnvelope} /><i className="fa fa-envelope fa-fw w3-xxlarge w3-margin-right"></i><a href="mailto: office@innosw.hu" style={{textDecoration:'none'}}>Email:
                        office@innosw.hu</a></p>
                    <p><FontAwesomeIcon icon={faLinkedinIn} /><i className="fa fa-linkedin fa-fw w3-xxlarge w3-margin-right"></i><a
                        href="https://www.linkedin.com/company/innovative-software-solutions-kft" style={{textDecoration:'none'}} target="_blank">Innovative
                        Software
                        Solutions Kft.</a></p>
                    <p><FontAwesomeIcon icon={faSquareFacebook} /><i className="fa fa-facebook-square fa-fw w3-xxlarge w3-margin-right"></i><a
                        href="https://www.facebook.com/innosw.hu/" style={{textDecoration:'none'}} target="_blank">Innovative Software Solutions Kft.</a>
                    </p>
                    <p><FontAwesomeIcon icon={faInstagram} /><i className="fa fa-instagram fa-fw w3-xxlarge w3-margin-right"></i><a
                        href="https://www.instagram.com/innoswhu/" style={{textDecoration:'none'}} target="_blank">@innoswhu</a></p>
                </div>
                <div className="map-responsive">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2688.6749943395957!2d19.127016315872186!3d47.63244859418485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d128a778372d%3A0xa9baf0b0f849be59!2zRHVuYWtlc3ppLCBGxZEgw7p0IDQxLCAyMTIw!5e0!3m2!1shu!2shu!4v1615890924454!5m2!1shu!2shu"
                        width={600} height={450} allowFullScreen="" loading="lazy"></iframe>
                </div>
            </div>

        </>
    )
}
