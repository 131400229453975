import objectDetectionTranslationsDe from "./objectDetectionTranslationsDe";
import aiWorkshopTranslationsDe from "./aiWorkshopTranslationsDe";
import waterMeterTranslationsDe from "./waterMeterTranslationsDe";
import simulationTranslationsDe from "./simulationTranslationsDe";
import gisTranslationsDe from "./gisTranslationsDe";
import cadBimTranslationsDe from "./cadBimTranslationsDe";

const referenceTranslationsDe = {
  generativAICardTitle: "Innosw AI Image Generator",
  generativAICardText:
    "Verwandeln Sie Skizzen und einfache 3D-Ansichten in beeindruckende visuelle Entwürfe",
  objectDetectionCardTitle: "AI-basierte Objekterkennung",
  objectDetectionCardText:
    "Erkennen Sie jedes Symbol mit anpassbaren AI-Modellen",
  cadBimCardTitle: "CAD/BIM Entwicklungen",
  cadBimCardText:
    "Steigern Sie die Effizienz von Konstruktion und Engineering durch maßgeschneiderte Add-ons und Automatisierung",
  simulationCardTitle: "3D Simulationen",
  simulationCardText:
    "Simulationen und Berechnungen an virtuellen 3D-Modellen in Unity3D",
  gisCardTitle: "Geographical Information System Entwicklungen",
  gisCardText:
    "Interpretation, Verarbeitung und Vervollständigung großer Mengen geografischer Daten",
  watermeterCardTitle: "Verwaltungssystem für den Austausch von Wasserzählern",
  watermeterCardText:
    "Vollständiges Workflow-Management auf Web- und mobilen Plattformen",
  workshopCardTitle: "AIdeation workshop",
  workshopCardText:
    "Entwicklung von Fähigkeiten zur Anwendung künstlicher Intelligenz in anderthalb Tagen für Teams",

  ...objectDetectionTranslationsDe,
  ...aiWorkshopTranslationsDe,
  ...waterMeterTranslationsDe,
  ...simulationTranslationsDe,
  ...gisTranslationsDe,
  ...cadBimTranslationsDe,
};

export default referenceTranslationsDe;
