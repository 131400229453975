import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Header from "../../../Header/Header";
import SnowComponent from "../../../Snow/Snow";

import "./AIWorkShop.css";

export default function AIWorkShopComponent(props) {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Container fluid style={{ marginTop: "50px", marginBottom: "50px" }}>
        <Row style={{ justifyContent: "center" }}>
          <h2 style={{ textAlign: "center" }}>{t("aiWorkshopTitle")}</h2>
          <img
            src="images/references/workshop_content.png"
            style={{
              border: "2px solid darkgray",
              boxShadow: "2px 4px 18px #707070",
              margin: "5vh",
              maxWidth: "60%",
              height: "auto",
            }}
            alt=""
          />
          <Col sm={7}>
            <p>{t("aiWorkshopP1")}</p>
            <ul>
              <li>
                <b>{t("aiWorkshopLi1")}</b>
              </li>
              <li>
                <b>{t("aiWorkshopLi2")}</b>
              </li>
              <li>
                <b>{t("aiWorkshopLi3")}</b>
                <ul>
                  <li>{t("aiWorkshopLi3_li1")}</li>
                </ul>
              </li>
              <li>
                <b>{t("aiWorkshopLi4")}</b>
                <ul>
                  <li>{t("aiWorkshopLi4_li1")}</li>
                  <li>{t("aiWorkshopLi4_li2")}</li>
                  <li>{t("aiWorkshopLi4_li3")}</li>
                  <li>{t("aiWorkshopLi4_li4")}</li>
                  <li>{t("aiWorkshopLi4_li5")}</li>
                  <li>{t("aiWorkshopLi4_li6")}</li>
                  <li>{t("aiWorkshopLi4_li7")}</li>
                  <li>{t("aiWorkshopLi4_li8")}</li>
                  <li>{t("aiWorkshopLi4_li9")}</li>
                </ul>
              </li>
              <li>
                <b>{t("aiWorkshopLi5")}</b>
                <ul>
                  <li>{t("aiWorkshopLi5_li1")}</li>
                  <li>{t("aiWorkshopLi5_li2")}</li>
                </ul>
              </li>
              <li>
                <b>{t("aiWorkshopLi6")}</b>
              </li>
              <li>
                <b>{t("aiWorkshopLi7")}</b>
                <ul>
                  <li>{t("aiWorkshopLi7_li1")}</li>
                  <li>{t("aiWorkshopLi7_li2")}</li>
                </ul>
              </li>
            </ul>
            <p>{t("aiWorkshopP2")}</p>
          </Col>
        </Row>
      </Container>
      <SnowComponent />
    </>
  );
}
