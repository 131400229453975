const objectDetectionTranslationsEn = {
  objectDetectionTitle: "Object detection AI",
  objectDetectionContentP1:
    "The object recognition software we have ve developed allows the recognition of technological equipment and devices on engineering plans, drawings, graphical diagrams, basically on any type of document. The software uses artificial intelligence-based solutions for object detection and text recognition.",
  objectDetectionContentP2:
    "After initial training, it can identify the types of objects, read associated labels, and to some extent, determine their points of connection. Our clients have the option to train the models independently and customarily, but we are also available to assist when needed, or to train the models.",
  objectDetectionContentP3:
    "With this software, you can easily digitalize and analyze any engineering plan, drawing, diagram, or scanned document. In this way, you can effectively enhance the transparency of technological systems and significantly simplify the precise positioning of equipment and devices within the system, in nearly any environment. ",
};

export default objectDetectionTranslationsEn;
