const solarPanelTranslationsEn = {
  solarPanelTitle: "Estimating urban solar energy potential",
  solarPanelP1:
    "In this project, we estimated the energy production potential of urban roofs and parking lots. The process started by creating a detailed 3D terrain model of the city using Unity, based on a GeoTIFF image of the city taken from a top view. In this image, the value of each pixel indicated the altitude of a given point above sea level, allowing us to accurately map the urban landscape.",
  solarPanelP2:
    "Next, we extracted the coordinates of the vertices of each roof from geojson files. Using these coordinates, we were able to place solar panels on the 3D models of the roofs with high accuracy, paying attention to optimising surface area utilisation. In addition, we carefully paid attention to the layout of the solar panels to align them with the orientation of the flat and sloped roofs to ensure that they receive as much sunlight as possible.",
  solarPanelP3:
    "The key element of the project was the simulation of shading effects in Unity. By modelling the movement of the sun and its interaction with surrounding buildings, we were able to determine the extent of illuminance of the solar panels, which allowed us to provide a highly accurate estimation of the solar panels' energy production.",
  solarPanelP4:
    "While using the software, our partner worked with approximately 300,000 roofs, on which we allocated nearly 3 million solar panels. In addition, they had solar radiation data for every hour of every day for a year, which resulted in a huge amount of data, as the radiation data needed to be used for every single solar panel (about 3 million). Therefore this project required an extreme amount of computing power however, our software design allowed for efficient parallelization, which significantly reduced the processing time. Without parallelization, the calculations could have taken up to a month, but this way our client was able to get on with their work seamlessly.",
};

const acVolumeCalculationTranslationsEn = {
  acVolumeCalculationTitle:
    "Calculation of Surface Material Quantity in ArchiCAD ",
  acVolumeCalculationP1:
    "The aim of the program is to accurately calculate the surface material quantities in each room based on the BIM model created in ArchiCAD. The development was necessary because the built-in quantity calculation in ArchiCAD does not give accurate results in case of insufficiently precise 3D modelling. For example, if certain building elements are not exactly in contact on their surfaces, but there is a volumetric interference between them, ArchiCAD will already produce a rather inaccurate calculation.",
  acVolumeCalculationP2:
    "InnoSW's solution exports the 3D model of the building elements created in ArchiCAD to an intermediate file and then performs the quantity takeoff in Unity 3D. Unity has a wealth of built-in functions for handling three-dimensional surface meshes, vector geometry operations and programming radius tracking calculations. In addition, easy parallelization of calculations is also handled in Unity 3D's function libraries. Thanks to the above, the time required for development was greatly reduced.",
  acVolumeCalculationP3:
    "The surface calculation itself is based on probability and sampling. The algorithm randomly takes several million samples with a uniform distribution on the surface of the exported triangle mesh. At each sampling point, it decides whether the given point is visible or obscured by other model elements. For each triangle, the ratio of obscured sampling points to the total number of samples can be used to calculate how much surface area of the triangle contributes to the total surface to be calculated.",
  acVolumeCalculationP4:
    "Following the sampling, the program sums up the surfaces of triangles found in each material in each room, thus determining the exact surface quantity for each material. The calculated quantities are returned to ArchiCAD in the form of a list, in which each quantity value is also assigned to the room and material identifier.",
};

const simulationTranslationsEn = {
  ...solarPanelTranslationsEn,
  ...acVolumeCalculationTranslationsEn,
};

export default simulationTranslationsEn;
