import { useTranslation } from "react-i18next";

export default function useCadBimContent() {
  const { t } = useTranslation();

  const listStyle = {
    textAlign: "left",
    padding: "0",
  };

  const listItemStyle = {
    marginBottom: "5px",
    marginLeft: "10px",
  };

  const generateListContent = (prefix, count) => {
    let items = [];
    for (let i = 1; i <= count; i++) {
      const translationKey = `${prefix}Li${i}`;
      items.push(
        <li key={translationKey} style={listItemStyle}>
          {t(translationKey)}
        </li>
      );
    }
    return <ul style={listStyle}>{items}</ul>;
  };

  const archiCadContent = generateListContent("archiCad", 7);
  const autoCadContent = generateListContent("autoCad", 1);
  const revitContent = generateListContent("revit", 5);
  const navisWorksContent = generateListContent("navisWorks", 3);
  const axisVmContent = generateListContent("axisVm", 4);

  return {
    archiCadContent,
    autoCadContent,
    revitContent,
    navisWorksContent,
    axisVmContent,
  };
}
