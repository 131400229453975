const waterMeterTranslationsEn = {
  title: "Water Meter Replacement Management System",
  description:
    "A system used to manage the water meter replacement and all related workflows.",
  usability:
    "Easy and secure to use, our web and mobile app makes it easy to see your daily workflow with airy ease.",

  keyFeatures: "Key features",
  keyFeatureLi1: "Use the latest web-based technology",
  keyFeatureLi2:
    "Management of seals and water meter inventory, seal and water meter types, queries, which seals are on who, where they are installed, etc.",
  keyFeatureLi3: "Google address search when placing an order",
  keyFeatureLi4:
    "Price and working time will be selected based on the price list",
  keyFeatureLi5: "Flexible time window setting option",
  keyFeatureLi6:
    "Automatic planning in two phases: plumber assignment followed by route planning",
  keyFeatureLi7:
    "Google-based traffic-dependent daily planning, focus on increasing utilisation",
  keyFeatureLi8: "Manual planning option",
  keyFeatureLi9:
    "Customer communication: order confirmation and estimated arrival time sent per email",
  keyFeatureLi10:
    "Order picking via calendar scheduler (taking into account workload, districts/cities actively visited that day)",
  keyFeatureLi11:
    "Work follow-up, post invoicing option, view invoicing status, link to external invoicing provider",
  keyFeatureLi12: "Data export to excel, analyses",
  keyFeatureLi13: "Activity log",

  mobileAppFeatures: "Key features of the mobile app",
  mobileAppFeatureLi1: "Recording of water meter replacements and other items",
  mobileAppFeatureLi2:
    "Seals + water meter ID: Barcode reading + searchable list selection option",
  mobileAppFeatureLi3:
    "Data recording available without internet connection, even the whole day can be recorded without internet",
  mobileAppFeatureLi4:
    "Invoicing via external invoicing provider (szamlazz.hu) API, invoice sent by email and/or printed locally",
  mobileAppFeatureLi5: "Take photos, backup on mobile device and at the centre",
  mobileAppFeatureLi6: "Documentation of failed work",
  mobileAppFeatureLi7: "Completion of worksheet digitally",
  mobileAppFeatureLi8: "Worksheet digitally signed by client",
  mobileAppFeatureLi9: "Send worksheet by email",
  mobileAppFeatureLi10:
    "Job details immediately available in the centre, together with the signed worksheet",

  evolutionStatement: "The system is constantly evolving as needs arise.",
};

export default waterMeterTranslationsEn;
