import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./utils/i18n";
import WaterMeter from "./components/References/Pages/WaterMeter/WaterMeter";
import { Route, Routes } from "react-router-dom";
import CadBimDevelopments from "./components/References/Pages/CAD_BIM/CadBim";
import AIWorkShopComponent from "./components/References/Pages/AIWorkShop/AIWorkShop";
import ObjectDetection from "./components/References/Pages/ObjectDetection";
import Gis from "./components/References/Pages/Gis";
import Simulations from "./components/References/Pages/Simulations";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/watermeter" element={<WaterMeter />} />
        <Route path="/cad-bim" element={<CadBimDevelopments />} />
        <Route path="/aiworkshop" element={<AIWorkShopComponent />} />
        <Route path="/objectdetection" element={<ObjectDetection />} />
        <Route path="/gis" element={<Gis />} />
        <Route path="/3dsimulation" element={<Simulations />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
