const objectDetectionTranslationsHu = {
  objectDetectionPageTitle: "Objektum felismerés AI alapon",
  objectDetectionContentP1:
    "Az általunk fejlesztett objektum felismerő szoftver lehetővé teszi technológiai berendezések és eszközök felismerését, tervrajzokon, grafikus diagramokon vagy szinte bármilyen dokumentumon. A szoftver mesterséges intelligencia alapú megoldásokat alkalmaz, az eszközök felismeréséhez és a hozzájuk tartozó szövegek olvasásához. ",

  objectDetectionContentP2:
    "Előzetes tanítás után képes beazonosítani az eszközök típusát, leolvasni a hozzájuk tartozó feliratot, és bizonyos mértékig meghatározni az egymással való kapcsolódási pontokat. A modellek tanítását ügyfeleink teljesen önállóan, tetszőlegesen egyedileg is végezhetik, ám igény esetén mi is tudunk ebben segíteni. ",
  objectDetectionContentP3:
    "Ezzel a szoftverrel könnyedén digitalizálhat és elemezhet bármilyen diagramot, tervrajzot vagy scannelt dokumentumot. Ily módon hatékonyan növelheti a technológiai rendszerek átláthatóságát és nagyságrendekkel könnyebbé teheti a rendszerben szereplő berendezések és eszközök pontos helyzetének meghatározását szinte bármilyen környezetben. ",
};

export default objectDetectionTranslationsHu;
