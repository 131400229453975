export default function Partner({ imageSrc, imageAlt, webPage }) {
  const image = (
    <img
      src={imageSrc}
      className="w3-round w3-hover-grayscale w150m20tac"
      alt={imageAlt}
    />
  );

  return webPage ? (
    <a href={webPage} target="_blank" rel="noopener noreferrer">
      {image}
    </a>
  ) : (
    image
  );
}
