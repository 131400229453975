import "./partners.css";
import { useTranslation } from "react-i18next";
import Partner from "./Partner";

export default function Partners() {
  const { t } = useTranslation();

  return (
    <div
      id="partners"
      className="w3-padding-32 w3-content w3-text-grey"
      style={{ marginBottom: 64 }}
    >
      <h2 style={{ textAlign: "left" }}>{t("partners")}</h2>
      <hr className="w3-opacity" />
      <Partner
        imageSrc="images/ceh_logo.png"
        imageAlt="CEH"
        webPage="https://ceh.hu/"
      />
      <Partner
        imageSrc="images/evosoft_logo.png"
        imageAlt="evosoft Hungary Kft."
        webPage="https://www.evosoft.hu/"
      />
      <Partner
        imageSrc="images/iconsoft_logo.png"
        imageAlt="iConsoft Kft."
        webPage="https://iconsoft.hu/"
      />
      <Partner
        imageSrc="images/trenecon_logo.bmp"
        imageAlt="Trenecon"
        webPage="https://www.trenecon.hu/"
      />
      <Partner
        imageSrc="images/werner.png"
        imageAlt="Werner"
        webPage="https://vizoracsere.info/"
      />
      <Partner
        imageSrc="images/infoportal.png"
        imageAlt="Info.portal Kft,"
        webPage="https://www.info-portal.hu/"
      />
      <Partner
        imageSrc="images/OSZB.png"
        imageAlt="Óbudai SzerelvényBau"
        webPage="https://www.szerelvenybau.hu/"
      />
      <Partner
        imageSrc="images/lapoldal_logo.webp"
        imageAlt="Lápoldal Kft."
        webPage="https://lapoldalkft.hu/"
      />
      <Partner imageSrc="images/dk_card.jpg" imageAlt="Dunakeszi kártya" />
      <Partner imageSrc="images/dk_logo.png" imageAlt="Dunakeszi" />
      <Partner
        imageSrc="images/mi_koal.png"
        imageAlt="MI Koalíció"
        webPage="https://ai-hungary.com/"
      />
    </div>
  );
}
