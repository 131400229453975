const objectDetectionTranslationsDe = {
  objectDetectionTitle: "Objekterkennung AI",
  objectDetectionContentP1:
    "Die von uns entwickelte Objekterkennungssoftware ermöglicht die Erkennung von technologischen Geräten und Anlagen auf technischen Plänen, Zeichnungen, grafischen Diagrammen und grundsätzlich auf jeder Art von Dokument. Die Software nutzt auf künstlicher Intelligenz basierende Lösungen zur Objekterkennung und Texterkennung.",
  objectDetectionContentP2:
    "Nach einem anfänglichen Training kann sie die Arten von Objekten identifizieren, zugehörige Beschriftungen lesen und bis zu einem gewissen Grad ihre Verbindungspunkte bestimmen. Unsere Kunden haben die Möglichkeit, die Modelle eigenständig und auf eigene Faust zu trainieren, aber wir stehen auch zur Verfügung, um sie bei Bedarf zu unterstützen bzw. die.Modelle zu trainieren.",
  objectDetectionContentP3:
    "Mit dieser Software können Sie beliebige technische Pläne, Zeichnungen, Diagramme oder gescannte Dokumente einfach digitalisieren und analysieren. Auf diese Weise können Sie die Transparenz technologischer Systeme effektiv verbessern und die präzise Positionierung von Geräten und Anlagen innerhalb des Systems in nahezu jeder Umgebung erheblich vereinfachen.",
};

export default objectDetectionTranslationsDe;
