

export default function ShowComponent() {
    return (
        <>
         {localStorage.getItem('xmas') === 'true' ? (
                <div className="snowflakes" aria-hidden="true">
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❆
                </div>
                <div className="snowflake">
                    ❄
                </div>
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❆
                </div>
                <div className="snowflake">
                    ❄
                </div>
                <div className="snowflake">
                    ❅
                </div>
                <div className="snowflake">
                    ❆
                </div>
                <div className="snowflake">
                    ❄
                </div>
            </div>  ): '' }</>
        )
}
