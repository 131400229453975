const gisTranslationsDe = {
  gisTitle: "Geographical Information System Entwicklungen",
  gisContentP1:
    "In unserem Projekt haben wir die Solarenergiekapazität eines bestimmten Stadtgebiets ermittelt. Wir verwendeten ein detailliertes LOD-Modell, das aus einer Klassifizierung von LIDAR-Punktwolkendaten bestand. Das Modell umfasste Gebäude mit ihren Dächern und Wänden und enthielt Parkflächen.  Unser Ziel war es, die solare Energiekapazität (KWP) für jedes Dach und jeden Parkplatz in diesem Gebiet zu berechnen.",
  gisContentP2:
    "Das LOD2-Modell wurde aus der GIS-Software (QGIS) exportiert und in die Unity 3D-Software importiert. Dann haben wir einen intelligenten Greedy-Algorithmus entwickelt, um die entsprechenden Sonnenkollektoren für Dächer und Parkplätze zu erstellen, wobei verschiedene Regeln berücksichtigt wurden. Um die Datensicherheit zu gewährleisten, verwendeten wir einen effizienten hybriden Verschlüsselungsalgorithmus für alle Datenübertragungen.",
  gisContentP3:
    "Im Rahmen des Projekts wurden stündlich Schatteneffektsimulationen auf der Grundlage eines Geländemodells durchgeführt, die Daten für Gebäude mit mehreren Dächern zusammengefasst und schließlich die resultierenden Daten im GIS-Format exportiert. Dieses Projekt hat in hohem Maße dazu beigetragen, unser Fachwissen auf dem Gebiet der räumlichen Datenverarbeitung zu erweitern und wertvolle Erkenntnisse über das Solarenergiepotenzial städtischer Umgebungen zu gewinnen.",
};

export default gisTranslationsDe;
