import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { Col } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Header from "../../Header/Header";
import SnowComponent from "../../Snow/Snow";

export default function Simulations() {
  const { t } = useTranslation();

  const dividerStyle = {
    height: "3px", // Makes the divider thicker
    backgroundColor: "#333", // Gives the divider a darker color
    border: "none", // Removes any default border
    margin: "40px 0", // Adds vertical space around the divider
  };
  return (
    <>
      <Header />
      <Container fluid style={{ marginTop: "50px" }}>
        <Row style={{ justifyContent: "center" }}>
          <Col sm={5} md={4}>
            {/* Images and content for the solar panel project */}
            <img
              src="images/references/gis_content1.png"
              style={{
                border: "2px solid darkgray",
                boxShadow: "2px 4px 18px #707070",
                marginTop: "20px",
              }}
              alt="Solar Panel Project"
            />
            <img
              src="images/references/gis_content2.png"
              style={{
                border: "2px solid darkgray",
                boxShadow: "2px 4px 18px #707070",
                marginTop: "20px",
              }}
              alt="Solar Panel Project"
            />
          </Col>
          <Col sm={5} md={7}>
            <h2 style={{ marginBottom: "40px" }}>{t("solarPanelTitle")}</h2>
            <p>{t("solarPanelP1")}</p>
            <p>{t("solarPanelP2")}</p>
            <p>{t("solarPanelP3")}</p>
            <p>{t("solarPanelP4")}</p>
          </Col>
        </Row>

        <hr style={dividerStyle} />

        <Row style={{ justifyContent: "center" }}>
          <Col sm={5} md={4}>
            <img
              src="images/references/ac_3d.png"
              style={{
                border: "2px solid darkgray",
                boxShadow: "2px 4px 18px #707070",
                marginTop: "20px",
              }}
              alt=""
            />
          </Col>
          <Col sm={5} md={7}>
            <h2 style={{ marginBottom: "40px" }}>
              {t("acVolumeCalculationTitle")}
            </h2>
            <p>{t("acVolumeCalculationP1")}</p>
            <p>{t("acVolumeCalculationP2")}</p>
            <p>{t("acVolumeCalculationP3")}</p>
            <p>{t("acVolumeCalculationP4")}</p>
          </Col>
        </Row>
      </Container>
      <SnowComponent />
    </>
  );
}
