const waterMeterTranslationsHu = {
  title: "Vízóracsere Management Rendszer",
  description:
    "A szoftver vízórák cseréjének és az ahhoz kapcsolódó tevékenységek összes munkafolyamatának felügyeletére használható rendszer.",
  usability:
    "Könnyen és biztonságosan kezelhető webes és mobilos alkalmazásunk segítségével légies könnyedséggel látható el a napi munkamenet.",

  keyFeatures: "Főbb jellemzői",
  keyFeatureLi1: "Legfrissebb webes technológia használata",
  keyFeatureLi2:
    "Plomba és vízóraraktár kezelése, plomba és vízóratípusok, lekérdezések, melyik plomba kinél van, hol van felszerelve, stb.",
  keyFeatureLi3: "Google címkereső a rendelésfelvitelkor",
  keyFeatureLi4: "Az árlista alapján az ár és a munkaidő kiválasztásra kerül",
  keyFeatureLi5: "Rugalmas időablak beállítási lehetőség",
  keyFeatureLi6:
    "Automatikus tervezés két fázisban: szerelő kiosztás majd útvonaltervezés",
  keyFeatureLi7:
    "Google alapú forgalomfüggő napi tervezés, fókusz a kihasználtság növelése",
  keyFeatureLi8: "Kézi tervezési lehetőség",
  keyFeatureLi9:
    "Ügyfél kommunikáció: Rendelésmegerősítés és várható érkezési időpont küldése email-ben",
  keyFeatureLi10:
    "Rendelésfelvitel naptáras ütemező segítségével (munka telítettség, aznapi aktívan bejárt kerületek/városok figyelembevételével)",
  keyFeatureLi11:
    "Munka utánkövetése, utólagos számlázási lehetőség, számlázás státusza megtekinthető, link a számlázz.hu számla oldalra",
  keyFeatureLi12: "Adatexport lehetőség excelbe, elemzések",
  keyFeatureLi13: "Tevékenységnapló",

  mobileAppFeatures: "Mobil applikáció főbb jellemzői",
  mobileAppFeatureLi1: "Vízóracserék és Egyéb felmerülő tételek rögzítése",
  mobileAppFeatureLi2:
    "Plomba + vízóra azonosító: Vonalkód olvasás + keresős listából kiválasztási lehetőség",
  mobileAppFeatureLi3:
    "Adatrögzítés internetkapcsolat nélkül is elérhető, akár a teljes nap rögzíthető internet nélkül",
  mobileAppFeatureLi4:
    "Számlázás számlázz.hu API segítségével, számla küldés Email-ben és/vagy nyomtatható helyben",
  mobileAppFeatureLi5:
    "Fényképek készítése, biztonsági másolat a mobileszközön és a központban",
  mobileAppFeatureLi6: "Meghiúsult munka dokumentálása",
  mobileAppFeatureLi7: "Munkalap kitöltése digitálisan",
  mobileAppFeatureLi8: "Ügyfél által digitálisan aláírható munkalap",
  mobileAppFeatureLi9: "Munkalap küldése emailben",
  mobileAppFeatureLi10:
    "A munka részletei azonnal elérhetők a központban, az aláírt munkalappal együtt",

  evolutionStatement:
    "A rendszert folyamatosan fejlesztjük a felmerülő igények alapján.",
};

export default waterMeterTranslationsHu;
