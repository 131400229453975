export default function Footer() {
  return (
    <div>
      <footer className="w3-container w3-opacity w3-xlarge">
        <p className="copyright">
          &copy; {new Date().getFullYear()} Innovative Software Solutions Kft.
        </p>
      </footer>
    </div>
  );
}
