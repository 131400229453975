import { useTranslation } from "react-i18next";
import ReferenceCard from "./ReferenceCard";

export default function References() {
  const { t } = useTranslation();
  return (
    <>
      <div
        id="references"
        className="w3-padding-32 w3-content w3-text-grey"
        style={{ marginBottom: 64 }}
      >
        <h2 style={{ textAlign: "left" }}>{t("references")}</h2>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <ReferenceCard
            imagePath="images/references/image_generator_ai.png"
            title={t("generativAICardTitle")}
            text={t("generativAICardText")}
            navigateTo="https://image-generator.innosw.hu/"
            isExternalLink={true}
          />
          <ReferenceCard
            imagePath="images/references/workshop.png"
            title={t("workshopCardTitle")}
            text={t("workshopCardText")}
            navigateTo="/aiworkshop"
          />
          <ReferenceCard
            imagePath="images/references/object_detection.png"
            title={t("objectDetectionCardTitle")}
            text={t("objectDetectionCardText")}
            navigateTo="/objectdetection"
          />
          <ReferenceCard
            imagePath="images/developments.png"
            title={t("cadBimCardTitle")}
            text={t("cadBimCardText")}
            navigateTo="/cad-bim"
          />
          <ReferenceCard
            imagePath="images/references/3D_simulation.png"
            title={t("simulationCardTitle")}
            text={t("simulationCardText")}
            navigateTo="/3dsimulation"
          />
          <ReferenceCard
            imagePath="images/references/GIS.webp"
            title={t("gisCardTitle")}
            text={t("gisCardText")}
            navigateTo="/gis"
          />
          <ReferenceCard
            imagePath="images/watermeter.png"
            title={t("watermeterCardTitle")}
            text={t("watermeterCardText")}
            navigateTo="/watermeter"
          />
        </div>
      </div>
    </>
  );
}
