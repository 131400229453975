const gisTranslationsEn = {
  gisTitle: "Geographic Information System developments",
  gisContentP1:
    "In our project we determined the solar energy capacity of a specific urban area. We used a detailed LOD model, which was composed of a classification of LIDAR point cloud data. The model included buildings along with their roofs and walls and contained parking lots. Our goal was to calculate the solar energy capacity (KWP) for each roof and parking lot in this area.",
  gisContentP2:
    "The LOD2 model was exported from GIS software (QGIS) and imported into Unity 3D software. Then, we carefully designed a smart greedy algorithm to create the corresponding solar panels for roofs and parking lots, considering different rules. To ensure data security, we used an efficient hybrid encryption algorithm for all data transfers.",
  gisContentP3:
    "The project involved running shadow effect simulations based on a terrain model every hour, summarizing the data for buildings with multiple roofs, and finally exporting the resulting data in GIS format. This project has greatly contributed to expanding our expertise in the field of spatial computing, as well as providing valuable insights into the solar energy potential of urban environments.",
};

export default gisTranslationsEn;
