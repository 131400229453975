const autoCadTranslationsDe = {
  autoCadTitle: "Autodesk – AutoCAD",
  autoCadLi1: "Electrical distribution cabinet design",
};

const revitTranslationsDe = {
  revitTitle: "Autodesk – Revit",
  revitLi1: "Automatic publishing",
  revitLi2: "Automatic dimensioning",
  revitLi3: "Conditional selection",
  revitLi4: "Void provision identification",
  revitLi5: "WinWatt connection",
};

const navisWorksTranslationsDe = {
  navisWorksTitle: "Autodesk – NavisWorks",
  navisWorksLi1: "Automatic model update",
  navisWorksLi2: "Clash grouping",
  navisWorksLi3: "Rerun and Fix automatic clash detection",
};

const archiCadTranslationsDe = {
  archiCadTitle: "Graphisoft – ArchiCAD",
  archiCadLi1: "Automatic publishing",
  archiCadLi2: "Surface area calculation for each zone",
  archiCadLi3: "Automatic update of zone properties",
  archiCadLi4: "Identify building elements by geometry",
  archiCadLi5: "DWG translator synchronization",
  archiCadLi6: "Fixed ladder design",
  archiCadLi7: "Automatic dimensioning",
};

const axisVmTranslationsDe = {
  axisVmTitle: "AxisVM",
  axisVmLi1: "Querying nodal forces and moments",
  axisVmLi2: "Querying of fluctuating stresses for fatigue analysis",
  axisVmLi3: "Querying critical support forces",
  axisVmLi4: "Design of reinforced concrete columns",
};

const cadBimTranslationsDe = {
  cadBimTitle: "CAD/BIM-Entwicklungen",
  cadBimP1:
    "Unsere CAD/BIM-Entwicklungen werden neben Autodesk-Produkten auch für Graphisoft ArchiCAD und AxisVM erstellt. Ihr Zweck ist es, repetitive Aufgaben, die während des Entwurfs, der Dokumentation, der Mengenberechnung sowie der Zusammenarbeit auftreten, zu automatisieren und die Effizienz dieser Prozesse so weit wie möglich zu steigern.",
  cadBimP2:
    "Unsere bisher fertiggestellten Anwendungen und Zusatzmodule (auf Englisch):",
  ...autoCadTranslationsDe,
  ...revitTranslationsDe,
  ...navisWorksTranslationsDe,
  ...archiCadTranslationsDe,
  ...axisVmTranslationsDe,
};

export default cadBimTranslationsDe;
